import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';

function RenderQRCode(wifi_name, wifi_pass){
  var QRCode = require('qrcode')

  var qrCodeData = '';

  QRCode.toDataURL('WIFI:T:WPA;S:' + wifi_name +';P:' + wifi_pass + ';H:false;;', function (err, url) {
    console.log(url)
    qrCodeData =  url;
  })

  return (
    <img src={qrCodeData}></img>
  )
  }
  
  function App() {
      const [wifi_name, set_wifi_name] = useState("");
      const [wifi_pass, set_wifi_pass] = useState("");

  return (
    <div className="container-sm text-center">
      <header className="App-header">
        <h1>Wifi QR Login Generator</h1>
        <ul>
          <li>
            <label>Wifi Name:</label>
          </li>
          <li>
            <input className="form-control" type="text" value={wifi_name} onChange={(e) => set_wifi_name(e.target.value)} placeholder="Wifi Name..."></input>
          </li>
          <li>
            <label>Wifi Password:</label>
          </li>
          <li>
            <input className="form-control" type="password" value={wifi_pass} onChange={(e) => set_wifi_pass(e.target.value)} placeholder="Wifi Password..."></input>
          </li>      
        </ul>

        {wifi_name == "" || wifi_pass == "" ? null : RenderQRCode(wifi_name, wifi_pass)}
      </header>

    </div>
  );

}
export default App;
